export default class BusinessLabel {
  constructor() {
    if (!this.vars()) return false;
    this.setupEvents();
  }

  vars() {
    this.selectors = {
      label: 'data-business-label',
      option: 'data-business-label-option',
    };

    this.select = document.querySelector(`[${this.selectors.label}]`);

    if (!this.select) return false;

    this.option = this.select.getAttribute(this.selectors.option);
    this.target = this.select.getAttribute(this.selectors.label);

    if (!this.target || !this.option) return false;

    this.targetEl = document.getElementById(this.target);

    if (!this.targetEl) return false;

    this.label = this.targetEl.querySelector('label');
    this.input = this.targetEl.querySelector('input');
    this.helper = this.targetEl.querySelector('p');

    if (!this.label || !this.input || !this.helper) return false;

    const currentVal = this.select.value;

    this.setField(currentVal);

    return true;
  }

  setupEvents() {
    $(this.select).on('select2:select', (e) => {
      const { id } = e.params.data;
      this.setField(id);
    });
  }

  setField(id) {
    if (id == this.option) {
      this.label.innerHTML = 'Nazwa firmy';
      this.input.setAttribute('placeholder', 'Wpisz pełną nazwę swojej firmy');
      this.helper.innerHTML = 'Wpisz pełną nazwę swojej firmy';
    } else {
      this.label.innerHTML = 'Imię i nazwisko';
      this.input.setAttribute('placeholder', 'Wpisz swoje imię i nazwisko.');
      this.helper.innerHTML = 'Wpisz swoje imię i nazwisko.';
    }
  }
}
