export default class ShowRules {
  constructor() {
    if (!this.vars()) return false;
    this.setupEvents();
  }

  vars() {
    this.selectors = {
      rules: 'hiddenRules',
      visible: 'hiddenRules--visible',
      link: 'showRules',
      checkbox: 'showRulesMain'
    }

    this.hidden = document.querySelector(`.${this.selectors.rules}`);
    this.link = document.querySelector(`.${this.selectors.link}`);
    this.checkbox = document.querySelector(`.${this.selectors.checkbox}`);

    if (!this.hidden || !this.link || !this.checkbox) return false;

    return true;
  }

  setupEvents() {
    this.link.addEventListener('click', (e) => {
      e.preventDefault();
      this.open();
    });

    this.checkbox.addEventListener('change', () => {
      if (this.checkbox.checked) {
        this.open();
      }
    });

    window.addEventListener('show-rules-group', () => this.open());
  }

  open() {
    this.hidden.classList.add(this.selectors.visible);
    this.link.remove();
  }
}
