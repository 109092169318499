import ScrollTo from './ScrollTo';

export default class AvatarInput {
  constructor() {
    if (!this.vars()) return false;
    this.setupEvents();
  }

  vars() {
    this.selectors = {
      group: 'avatar-group',
      userInput: 'avatar-user-input',
      input: 'avatar-input',
    };

    this.validImagesExt = ['jpg', 'jpeg', 'png'];
    this.attachmentsExt = [
      'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'pdf', 'jpg', 'jpeg', 'png', 'zip', 'rar'
    ];

    this.groups = Array.from(document.querySelectorAll(`.${this.selectors.group}`));

    if (!this.groups.length) return false;

    this.errorBox = document.getElementById('js-error');
    this.successBox = document.getElementById('success-alert');

    return true;
  }

  setupEvents() {
    this.groups.forEach((group) => {
      this.initGroup(group);
    });

    window.addEventListener('avatar-file-update', (e) => {
      const { detail } = e;
      const group = detail.row.querySelector(`.${this.selectors.group}`);
      this.initGroup(group);
    });
  }

  initGroup(group) {
    const input = group.querySelector(`.${this.selectors.input}`);
    const labels = group.querySelectorAll('.fileOpen');
    let isAttachment = false;

    if (input) {
      if (input.getAttribute('data-type') === 'attachment') {
          isAttachment = true;
      }
      input.addEventListener('change', () => this.updateUserInput(group, input, isAttachment));
    }

    Array.from(labels).forEach((label) => {
      label.addEventListener('click', (e) => {
        if (label.classList.contains('remove')) {
          e.preventDefault();
        }
      });

      const btn = label.querySelector('button');
      if (btn) {
        btn.addEventListener('click', () => {
          this.removeImage(group, label, btn);
        });
      }
    });
  }

  updateUserInput(group, input, isAttachment) {
    if (!this.validateExtension(input, isAttachment) || !this.validateFileSize(input)) {
      group.classList.add('has-error');
      if (isAttachment) {
        $('#incorrectAttachmentModal').modal('show');
      } else {
        $('#incorrectModal').modal('show');
      }
      this.errorBox.style.display = 'block';
      if (this.successBox) this.successBox.style.display = 'none';
      ScrollTo.scroll(0);
      return false;
    } else {
      group.classList.remove('has-error');
    }
    const value = input.value;
    const userInput = group.querySelector(`.${this.selectors.userInput}`);
    const explodedValue = value.split('\\');
    if (userInput) {
      userInput.value = explodedValue[explodedValue.length - 1];
    }

    const btn = group.querySelector('.btn');
    if (!btn) return false;

    btn.innerHTML = 'Usuń';
    btn.classList.remove('btn-info');
    btn.classList.add('btn-danger');
    const label = btn.closest('.fileOpen');
    if (!label) return false;
    label.classList.add('remove');
  }

  validateExtension(input, isAttachment) {
    const ext = input.value.split('.').pop();
    const validExtensions = isAttachment ? this.attachmentsExt : this.validImagesExt;
    if (validExtensions.indexOf(ext) < 0 && input.getAttribute('name') !== 'csv_file') {
      input.value = '';
    }

    if (input.getAttribute('name') === 'csv_file') {
      return true;
    }

    return validExtensions.indexOf(ext) >= 0;
  }

  validateFileSize(input) {
    if (!input.files.length) return true;
    return input.files[0].size < 4000000;
  }

  removeImage(group, label, btn) {
    label.classList.remove('remove');
    label.classList.add('removed');
    btn.innerHTML = 'Dodaj plik';
    btn.classList.remove('btn-danger');
    btn.classList.add('btn-info');
    const inputs = group.querySelectorAll('input');
    Array.from(inputs).forEach((input) => {
      input.value = '';
    });
  }
}
