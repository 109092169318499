import Validator from '../Validator.js';

export default class BatchMail {
  constructor() {
    if (!this.vars()) return false;
    this.setupEvents();
  }

  vars() {
    this.selectors = {
      form: 'sendBatchNotification',
    };

    this.form = document.getElementById(this.selectors.form);

    if (!this.form) return false;

    this.fields = [
      {
        name: 'sender',
        rules: [
          {
            type: 'regexp',
            regexp:
              '[A-Z0-9a-z._%+-]+@[a-zęóąśłżźćńA-ZĘÓĄŚŁŻŹĆŃ0-9.-]+\\.[a-zęóąśłżźćńA-ZĘÓĄŚŁŻŹĆŃ]{2,50}',
          },
        ],
      },
      {
        name: 'ids',
        rules: [
          {
            type: 'select-multiple',
          },
        ],
      },
      {
        name: 'subject',
        rules: [
          {
            type: 'regexp',
            regexp: '^(.{3,60})$',
          },
        ],
      },
      {
        name: 'message',
        rules: [
          {
            type: 'regexp',
            regexp: '^(.[\\s\\S]{3,1500})$',
          },
        ],
      },
    ];

    this.validator = new Validator(this.fields, this.form);

    return true;
  }

  setupEvents() {
    this.form.addEventListener('submit', (event) => {
      if (!this.validator.validate()) event.preventDefault();
    });
  }
}
