export default class ProjectPmInput {
  constructor() {
    if (!this.vars()) return false;
    this.setupEvents();
    this.appendValues();
  }

  vars() {
    this.wrapper = document.querySelector('[data-workers-wrapper]');

    if (!this.wrapper) return false;

    return true;
  }

  setupEvents() {
    window.addEventListener('duplicate', () => this.appendValues());
    window.addEventListener('duplicate-remove', () => this.appendValues());
  }

  appendValues() {
    const radios = Array.from(this.wrapper.querySelectorAll('[name="project_pm_role"]'));
    let key = 1;
    radios.forEach((radio) => {
      if (!radio.closest('[data-duplicate-content-blueprint]')) {
        radio.value = key;
        key++;
      }
    });
  }
}
