import RegisterStep1 from './RegisterStep1';
import RegisterStep2 from './RegisterStep2';
import RegisterStep3 from './RegisterStep3';
import RegisterStep4 from './RegisterStep4';

const initRegisterValidators = () => {
  new RegisterStep1();
  new RegisterStep2();
  new RegisterStep3();
  new RegisterStep4();
};

export default initRegisterValidators;
