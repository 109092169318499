require('./bootstrap');

$.fn.select2.amd.require(['select2/selection/search'], function (Search) {
    var oldRemoveChoice = Search.prototype.searchRemoveChoice;
    Search.prototype.searchRemoveChoice = function () {
        oldRemoveChoice.apply(this, arguments);
        this.$search.val('');
    };
});

import initGlobals from './global/_initGlobals';
import initRegisterValidators from './validators/register/_initRegisterValidators';
import initEditValidators from './validators/edit/_initEditValidators';
import initNotificationValidators from './validators/notification/_initNotificationValidators';
import initSettingsValidators from './validators/settings/_initSettingsValidators';
import initProjectValidators from './validators/project/_initProjectValidators';

class Core {
  constructor() {
    initGlobals();
    initRegisterValidators();
    initEditValidators();
    initNotificationValidators();
    initSettingsValidators();
    initProjectValidators();
  }
}

new Core();

$.fn.datepicker.dates['en'] = {
  days: ['Niedziela', 'Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota'],
  daysShort: ['Nie', 'Pon', 'Wto', 'Śro', 'Czw', 'Pt', 'Sob'],
  daysMin: ['Nd', 'Pn', 'Wt', 'Śr', 'Cz', 'Pt', 'So'],
  months: [
    'Styczeń',
    'Luty',
    'Marzec',
    'Kwiecień',
    'Maj',
    'Czerwiec',
    'Lipiec',
    'Sierpień',
    'Wrzesień',
    'Październik',
    'Listopad',
    'Grudzień',
  ],
  monthsShort: ['Sty', 'Lut', 'Mar', 'Kwi', 'Maj', 'Cze', 'Lip', 'Sie', 'Wrz', 'Paź', 'Lis', 'Gru'],
  today: 'Dzisiaj',
  clear: 'Wyczyść',
  format: 'dd/mm/yyyy',
  titleFormat: 'MM yyyy' /* Leverages same syntax as 'format' */,
  weekStart: 1,
};

const editorEl = document.querySelector('.editor');

if (editorEl) {
  ClassicEditor
    .create(editorEl, {
      toolbar: {
        items: [
          '|',
          'bold',
          'italic',
          'link',
          'bulletedList',
          'numberedList',
          '|',
          'undo',
          'redo'
        ]
      },
      language: 'pl',
      licenseKey: '',
    })
    .then(editor => {
      window.editor = editor;
      window.editor.model.document.on('change', () => {
        window.editor.updateSourceElement();
        editorEl.dispatchEvent(
          new CustomEvent('editor-update')
        );
      });
    })
    .catch(error => {
      console.error('CK Editor');
    });
}
