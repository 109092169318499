export default class PreferedLangHelper {
  constructor() {
    if (!this.vars()) return false;
    this.setupEvents();
  }

  vars() {
    this.selectors = {
      lang: 'data-prefered-lang',
      helper: 'data-prefered-lang-helper',
    };

    this.getElements();
    return true;
  }

  getElements() {
    this.langs = Array.from(document.querySelectorAll(`[${this.selectors.lang}]`));
    this.helpers = Array.from(document.querySelectorAll(`[${this.selectors.helper}]`));
    this.setupFieldEvents();
  }

  setupEvents() {
    window.addEventListener('duplicate', () => this.getElements());
  }

  setupFieldEvents() {
    this.langs.forEach((lang) => {
      lang.addEventListener('change', () => this.handleCheck(lang));
    });
  }

  handleCheck(lang) {
    if (lang.checked) {
      this.resetHelpers();
      this.updateHelper(lang);
    }
  }

  resetHelpers() {
    this.helpers.forEach((helper) => {
      helper.value = '0';
    });
  }

  updateHelper(lang) {
    const label = lang.closest('label');
    const helper = label.querySelector(`[${this.selectors.helper}]`);
    helper.value = '1';
  }
}
