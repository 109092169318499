export default class CbShow {
  constructor() {
    if (!this.vars()) return false;
  }

  vars() {
    this.selectors = {
      checkbox: 'data-cb-show',
    };

    this.checkboxes = Array.from(document.querySelectorAll(`[${this.selectors.checkbox}]`));

    if (!this.checkboxes.length) return false;

    this.init();

    return true;
  }

  init() {
    this.checkboxes.forEach((checkbox) => {
      this.toggle(checkbox);
      checkbox.addEventListener('change', () => this.toggle(checkbox));
    });
  }

  toggle(checkbox) {
    const target = checkbox.getAttribute(this.selectors.checkbox);
    const targetEl = document.getElementById(target);
    if (checkbox.checked && targetEl) {
      targetEl.style.display = 'block';
    } else if (targetEl) {
      targetEl.style.display = 'none';
    }
  }
}
