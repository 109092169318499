export default class CharacterLimit {
  constructor() {
    if (!this.vars()) return false;
    this.setupEvents();
  }

  vars() {
    this.selectors = {
      item: 'data-limit',
    };

    this.items = Array.from(document.querySelectorAll(`[${this.selectors.item}]`));

    if (!this.items.length) return false;

    this.defaultLimit = 1500;

    return true;
  }

  setupEvents() {
    this.items.forEach((item) => this.setupItem(item));
  }

  setupItem(item) {
    let itemLimit = parseInt(item.getAttribute('data-limit'));
    if (!itemLimit || itemLimit === NaN) {
      itemLimit = this.defaultLimit;
    }
    item.value = item.value.substring(0, itemLimit);
    const wrapper = item.closest('div');
    let label = null;
    if (wrapper) {
      label = wrapper.querySelector('label');
      if (label) {
        label.innerHTML = `${label.innerHTML} <div class="charLimit">(<span class="charLimit__current">${item.value.length}</span>/<span>${itemLimit}</span>)</div>`;
      }
    }
    item.addEventListener('input', (e) => this.handleLimits(e, item, label, itemLimit));
    item.addEventListener('editor-update', (e) => this.handleLimits(e, item, label, itemLimit));
  }

  handleLimits(e, item, label, itemLimit) {
    if (item.value.length > itemLimit) {
      e.preventDefault();
      item.value = item.value.substring(0, itemLimit);
    }

    if (label) {
      const current = label.querySelector('.charLimit__current');
      if (current) {
        current.innerHTML = item.value.length;
      }
    }
  }
}
