import Validator from '../Validator.js';

export default class RegisterStep4 {
  constructor() {
    if (!this.vars()) return false;
    this.setupEvents();
  }

  vars() {
    this.selectors = {
      form: 'registerStep4',
      single: 'data-validate-single',
      multiple: 'data-validate-multiple',
      wrapper: 'data-validate-field',
      multipleWrapper: 'data-validate-fields',
    };

    this.form = document.getElementById(this.selectors.form);

    if (!this.form) return false;

    this.multipleWrappers = Array.from(
      this.form.querySelectorAll(`[${this.selectors.multipleWrapper}]`)
    );

    this.basicFields = [
      {
        name: 'comp_name',
        rules: [
          {
            type: 'regexpOrEmpty',
            regexp: '^(.{3,60})$',
          },
        ],
      },
      {
        name: 'comp_address',
        rules: [
          {
            type: 'regexpOrEmpty',
            regexp: '^(.{3,150})$',
          },
        ],
      },
      {
        name: 'comp_postal',
        rules: [
          {
            type: 'regexpOrEmpty',
            regexp: '^(.{3,10})$',
          },
        ],
      },
      {
        name: 'comp_city',
        rules: [
          {
            type: 'regexpOrEmpty',
            regexp: '^(.{3,60})$',
          },
        ],
      },
      {
        name: 'comp_tax_number',
        rules: [
          {
            type: 'regexpOrEmpty',
            regexp: '^(.{3,11})$',
          },
        ],
      },
      {
        name: 'secondary_name',
        rules: [
          {
            type: 'regexpOrEmpty',
            regexp: '^(.{3,60})$',
          },
        ],
      },
      {
        name: 'secondary_address',
        rules: [
          {
            type: 'regexpOrEmpty',
            regexp: '^(.{3,150})$',
          },
        ],
      },
      {
        name: 'secondary_postal',
        rules: [
          {
            type: 'regexpOrEmpty',
            regexp: '^(.{3,10})$',
          },
        ],
      },
      {
        name: 'secondary_city',
        rules: [
          {
            type: 'regexpOrEmpty',
            regexp: '^(.{3,60})$',
          },
        ],
      },
      {
        name: 'emergency_name',
        rules: [
          {
            type: 'regexpOrEmpty',
            regexp: '^(.{3,60})$',
          },
        ],
      },
      {
        name: 'emergency_email',
        rules: [
          {
            type: 'regexpOrEmpty',
            regexp:
              '[A-Z0-9a-z._%+-]+@[a-zęóąśłżźćńA-ZĘÓĄŚŁŻŹĆŃ0-9.-]+\\.[a-zęóąśłżźćńA-ZĘÓĄŚŁŻŹĆŃ]{2,50}',
          },
        ],
      },
      {
        name: 'emergency_phone',
        rules: [
          {
            type: 'regexpOrEmpty',
            regexp:
              '\\+(9[976]\\d|8[987530]\\d|6[987]\\d|5[90]\\d|42\\d|3[875]\\d|2[98654321]\\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\\d{9,11}$',
          },
        ],
      },
      {
        name: 'bankNumber',
        rules: [
          {
            type: 'multipleRegexpOrEmpty',
            regexp: '^([a-zA-Z0-9\\s]{3,50})$',
          },
        ],
      },
      {
        name: 'bankSwift',
        rules: [
          {
            type: 'multipleRegexpOrEmpty',
            regexp: '^([a-zA-Z0-9]{3,11})$',
          },
        ],
      }
    ];

    this.setupValidator();

    return true;
  }

  setupEvents() {
    this.form.addEventListener('submit', (event) => {
      if (!this.validator.validate()) event.preventDefault();
    });
  }

  setupValidator() {
    this.fields = this.basicFields;

    this.multipleWrappers.forEach((wrapper) => {
      // const wrapperEl = this.multipleWrappers[0];
      // const select = wrapperEl.querySelector(`[${this.selectors.single}="select"]`);
      // const number = wrapperEl.querySelector(`[${this.selectors.single}="numberText"]`);
      // const name = wrapperEl.querySelector(`[${this.selectors.single}="nameText"]`);
      // if (select && name && number) {
      //   this.fields.push({
      //     name: select.getAttribute('name'),
      //     rules: [
      //       {
      //         type: 'bank-account-row',
      //         subfield: {
      //           name: number.getAttribute('name'),
      //           regexp: '^(.{3,50})$',
      //         },
      //         secondarySubfield: {
      //           name: name.getAttribute('name'),
      //           regexp: '^(.{3,50})$',
      //         }
      //       },
      //     ],
      //   });
      // }

      // if (number) {
      //   this.fields.push({
      //     name: 'bankNumber',
      //     rules: [
      //       {
      //         type: 'multipleRegexpOrEmpty',
      //         regexp: '^([a-zA-Z0-9\\s]{3,50})$',
      //       },
      //     ],
      //   });
      // }
    });

    this.validator = new Validator(this.fields, this.form);
  }
}
