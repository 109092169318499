export default class CheckboxToggleAll {
  constructor() {
    if (!this.vars()) return false;
    this.setupEvents();
  }

  vars() {
    this.selectors = {
      toggleAllCheckbox: 'data-toggle-all',
    };

    this.toggler = document.querySelector(`[${this.selectors.toggleAllCheckbox}]`);

    if (!this.toggler) return false;

    return true;
  }

  setupEvents() {
    this.toggler.addEventListener('click', () => this.toggle());
  }

  toggle() {
    const cbClass = this.toggler.getAttribute(this.selectors.toggleAllCheckbox);
    if (!cbClass) return false;
    const items = document.querySelectorAll(`.${cbClass}`);
    const checked = this.toggler.checked;
    Array.from(items).forEach((item) => {
      if (checked) {
        item.checked = true;
      } else {
        item.checked = false;
      }
    });
    CheckboxToggleAll.dispatchEvent('cbs-toggled');
  }

  static dispatchEvent(event, detail = {}) {
    window.dispatchEvent(
      new CustomEvent(event, {
        detail,
      })
    );
  }
}
