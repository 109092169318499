export default class Duplicate {
  constructor() {
    if (!this.vars()) return false;
    this.setupEvents();
  }

  vars() {
    this.selectors = {
      wrapper: 'data-duplicate',
      row: 'data-duplicate-content-row',
      blueprint: 'data-duplicate-content-blueprint',
      button: 'data-duplicate-add',
      init: 'data-duplicate-init',
      remove: 'data-duplicate-remove',
    };

    this.wrappers = document.querySelectorAll(`[${this.selectors.wrapper}]`);

    if (!this.wrappers.length) return false;

    return true;
  }

  setupEvents() {
    Array.from(this.wrappers).forEach((wrapper) => {
      const buttons = wrapper.querySelectorAll(`[${this.selectors.button}]`);
      Array.from(buttons).forEach((button) => {
        button.addEventListener('click', () => this.duplicate(wrapper, button));
      });

      const removeButtons = wrapper.querySelectorAll(`[${this.selectors.remove}]`);
      Array.from(removeButtons).forEach((button) => {
        button.addEventListener('click', () => this.remove(button));
      });
    });
  }

  duplicate(wrapper, button) {
    const blueprint = wrapper.querySelector(`[${this.selectors.blueprint}]`);
    const newRow = blueprint.cloneNode(true, true);
    newRow.removeAttribute(this.selectors.blueprint);
    newRow.setAttribute(this.selectors.row, true);
    newRow.classList.remove('hidden');
    wrapper.append(newRow);

    const initializables = newRow.querySelectorAll(`[${this.selectors.init}]`);
    Array.from(initializables).forEach((initializable) => {
      const type = initializable.getAttribute(this.selectors.init);
      initializable.removeAttribute(this.selectors.init);

      if (type === 'select2') {
        initializable.classList.add('select2');
        $(initializable).select2().change(() => {
          window.dispatchEvent(new CustomEvent('select-changed'));
        });
        Duplicate.dispatchEvent('select-changed');
      } else if (type === 'datePicker') {
        initializable.classList.add('datePicker');
        $(initializable).datepicker({ autoclose: true });
      } else if (type === 'userSelect') {
        initializable.classList.add('userSelect');
        $(initializable).select2({ templateResult: formatWithAvatar });
      } else if (type === 'for') {
        let forAttr = initializable.getAttribute('for');
        const targetEl = newRow.querySelector('input[type="file"]');
        const targetElHidden = newRow.querySelector('input[type="hidden"]');
        forAttr = `${forAttr}${Date.now()}`;
        initializable.setAttribute('for', forAttr);
        targetEl.setAttribute('id', forAttr);
        targetElhidden.setAttribute('name', forAttr);
        initializable.closest('div').classList.add('avatar-group');
        initializable.querySelector('input').classList.add('avatar-user-input');
        targetEl.classList.add('avatar-input');
        Duplicate.dispatchEvent('avatar-file-update', { row: newRow });
      } else if (type === 'onclick') {
        let forAttr = initializable.getAttribute('data-for');
        const targetEl = newRow.querySelector('input[type="file"]');
        forAttr = `${forAttr}${Date.now()}`;
        initializable.setAttribute(
          'onclick',
          `if (this.classList.contains('removed')) { this.classList.remove('removed'); return false; } document.getElementById('${forAttr}').click();`
        );
        targetEl.setAttribute('id', forAttr);
        initializable.parentNode.classList.add('avatar-group');
        initializable.querySelector('input').classList.add('avatar-user-input');
        targetEl.classList.add('avatar-input');
        Duplicate.dispatchEvent('avatar-file-update', { row: newRow });
      }
    });
    const buttonsWrapper = button.closest('div');
    const lastButton = newRow.querySelector(`[${this.selectors.remove}]`);
    lastButton.addEventListener('click', () => this.remove(lastButton));

    if (buttonsWrapper) {
      const removeButton = buttonsWrapper.querySelector(`[${this.selectors.remove}]`);
      if (removeButton) {
        removeButton.addEventListener('click', () => this.remove(removeButton));
      }
    }


    const addButton = newRow.querySelector(`[${this.selectors.button}]`);
    addButton.addEventListener('click', () => this.duplicate(wrapper, addButton));
    Duplicate.dispatchEvent('duplicate');
    this.resetAddMoreButton(newRow);
  }

  remove(button) {
    const row = button.closest(`[${this.selectors.row}]`);
    const wrapper = row.closest(`[${this.selectors.wrapper}]`);
    if (!wrapper) return false;
    const rows = wrapper.querySelectorAll(`[${this.selectors.row}]`);
    if (rows.length > 1) {
      row.remove();
    } else {
      const inputs = row.querySelectorAll('input[type="text"]');
      Array.from(inputs).forEach((input) => input.value = '');
      const selects = row.querySelectorAll('select');
      Array.from(selects).forEach((select) => $(select).val('-1').trigger('change'));
      const checks = row.querySelectorAll('input[type="checkbox"]');
      Array.from(checks).forEach((check) => check.checked = false);
      const radios = row.querySelectorAll('input[type="radio"]');
      Array.from(radios).forEach((radio) => radio.selected = false);
    }
    this.resetAddMoreButton(wrapper, true);
    Duplicate.dispatchEvent('duplicate-remove');
  }

  resetAddMoreButton(row, isRowWrapper = false) {
    const wrapper = isRowWrapper ? row : row.closest(`[${this.selectors.wrapper}]`);
    if (!wrapper) return false;
    const items = wrapper.querySelectorAll(`[${this.selectors.button}]`);
    if (items.length === 2) {
      if (items[0].closest('[data-duplicate-content-blueprint]')) {
        items[1].style.display = 'inline-block';
      } else {
        items[0].style.display = 'inline-block';
      }
    } else {
      Array.from(items).forEach((item, index) => {
        if (index < items.length - 1) {
          item.style.display = 'none';
        } else {
          item.style.display = 'inline-block';
        }
      });
    }
  }

  static dispatchEvent(event, detail = {}) {
    window.dispatchEvent(
      new CustomEvent(event, {
        detail,
      })
    );
  }
}
