/*
 * Example of use:
 *
 * Add:       '.expander' class to your clickable element
 * Add:       [data-target="{id}"] attribute with target ID
 * Optional:  [data-active-class="{class}"] attribute
 *            for custom class when tab is active
 *
 * Add:       '.expanderTab' class to expandable
 *            content wrapper element
 * Add:       {id} id to same wrapper
 * Optional:  [data-active-class="{class}"] attribute
 *            for custom class when tab is active
 *
 * Add:       '.expanderTab--inner' class to your
 *            expandable content
 * Optional   When you want toggle tab from other element inside it
 *            use [data-expand-closest] attribute
 */

export default class Expander {
  constructor() {
    if (this.setVars()) {
      this.initExpanderTabs();
      this.setEvents();
    }
  }

  setVars() {
    this.expanders = document.querySelectorAll('.expander');
    this.expanderActiveClass = 'expander--active';
    this.expanderTabActiveClass = 'expanderTab--active';
    this.expandClosest = document.querySelectorAll('[data-expand-closest]');
    if (!this.expanders) return false;

    return true;
  }

  initExpanderTabs(resize = false) {
    Array.from(this.expanders).forEach((item) => {
      const targetId = item.getAttribute('data-target');
      const target = document.getElementById(targetId);

      if (!target) return;

      const inner = target.querySelector('.expanderTab--inner');
      const { height } = inner.getBoundingClientRect();
      target.setAttribute('data-height', height);

      if (target.classList.contains('expanderTab--active') && !resize) {
        target.style.maxHeight = `${height}px`;
      }

      if (resize) {
        if (target.classList.contains(this.expanderTabActiveClass)) {
          target.style.maxHeight = `${height}px`;
        }
      }
    });
  }

  setEvents() {
    Array.from(this.expanders).forEach((item) => {
      item.addEventListener('click', (evt) => this.handleTabToggle(evt));
    });

    window.addEventListener('expander-hide-all', () => this.hideAll());
    window.addEventListener('expander-show-all', () => this.showAll());
    window.addEventListener('resize', () => this.initExpanderTabs(true));
    window.addEventListener('expander-refresh', () => this.initExpanderTabs(true));

    if (this.expandClosest.length) {
      Array.from(this.expandClosest).forEach((item) => {
        item.addEventListener('click', () => Expander.handleExpandClosestClick(item));
      });
    }

    window.addEventListener('expander-open', (event) => Expander.open(event));
  }

  static open(event) {
    Expander.handleExpandClosestClick(document.getElementById(event.detail.id));
  }

  static handleExpandClosestClick(item) {
    const wrapper = item.closest(`.${item.getAttribute('data-expand-closest')}`);
    const target = wrapper.querySelector('.expander');
    target.click();
  }

  handleTabToggle(item) {
    const clickEl = item.currentTarget;
    const targetId = clickEl.getAttribute('data-target');
    const target = document.getElementById(targetId);
    const activeClass = clickEl.getAttribute('data-class');

    if (!target) return;

    if (clickEl.hasAttribute('data-expander-collapse')) {
      this.hideAll();
    }

    this.toggleExpanderButton(clickEl);
    this.toggleExpanderTab(target);
  }

  toggleExpanderButton(clickEl) {
    const clickElActiveClass = clickEl.getAttribute('data-active-class');
    clickEl.classList.toggle(this.expanderActiveClass);

    if (clickElActiveClass) {
      clickEl.classList.toggle(clickElActiveClass);
    }
  }

  toggleExpanderTab(targetItem) {
    const target = targetItem;
    const targetActiveClass = target.getAttribute('data-active-class');

    this.setExpanderTabHeight(target);

    if (targetActiveClass) {
      target.classList.toggle(targetActiveClass);
    }

    target.classList.toggle(this.expanderTabActiveClass);

    const wrapper = target.closest('li') || target.closest('div');
    wrapper.classList.toggle('active');
    window.setTimeout(() => {
      Expander.dispatchEvent('expander-changed');
    }, 300);
  }

  setExpanderTabHeight(targetItem) {
    const target = targetItem;
    const targetHeight = target.getAttribute('data-height');
    if (target.classList.contains(this.expanderTabActiveClass)) {
      target.style.maxHeight = 0;
    } else {
      target.style.maxHeight = `${targetHeight}px`;
    }
  }

  hideAll() {
    Array.from(this.expanders).forEach((item) => {
      const customExpanderClass = item.getAttribute('data-active-class');
      const targetId = item.getAttribute('data-target');
      const target = document.getElementById(targetId);
      const customTargetClass = target.getAttribute('data-active-class');

      item.classList.remove(this.expanderActiveClass);
      target.classList.remove(this.expanderTabActiveClass);

      if (customExpanderClass) {
        item.classList.remove(customExpanderClass);
      }

      if (customTargetClass) {
        target.classList.remove(customTargetClass);
      }

      target.style.maxHeight = 0;

      const wrapper = target.closest('li') || target.closest('div');
      wrapper.classList.remove('active');
    });
  }

  showAll() {
    Array.from(this.expanders).forEach((item) => {
      const customExpanderClass = item.getAttribute('data-active-class');
      const targetId = item.getAttribute('data-target');
      const target = document.getElementById(targetId);
      const targetHeight = target.getAttribute('data-height');
      const customTargetClass = target.getAttribute('data-active-class');

      item.classList.add(this.expanderActiveClass);
      target.classList.add(this.expanderTabActiveClass);

      if (customExpanderClass) {
        item.classList.add(customExpanderClass);
      }

      if (customTargetClass) {
        target.classList.add(customTargetClass);
      }

      target.style.maxHeight = `${targetHeight}px`;

      const wrapper = target.closest('li') || target.closest('div');
      wrapper.classList.add('active');
    });
  }

  static dispatchEvent(event, detail = {}) {
    window.dispatchEvent(
      new CustomEvent(event, {
        detail,
      })
    );
  }
}
