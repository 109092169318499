export default class ShowPassword {
  constructor() {
    if (!this.vars()) return false;
    this.setupEvents();
  }

  vars() {
    this.selectors = {
      wrapper: 'showPasswordFormGroup',
      link: 'showPassword',
    };

    this.wrappers = document.querySelectorAll(`.${this.selectors.wrapper}`);

    if (!this.wrappers.length) return false;

    return true;
  }

  setupEvents() {
    Array.from(this.wrappers).forEach((wrapper) => {
      const link = wrapper.querySelector(`.${this.selectors.link}`);
      if (link) link.addEventListener('click', (e) => {
        e.preventDefault();
        this.toggle(wrapper, link);
      });
    });
  }

  toggle(wrapper, link) {
    const input = wrapper.querySelector('input');
    if (input) {
      if (input.classList.contains('pass-visible')) {
        input.type = 'password';
        input.classList.remove('pass-visible');
        link.innerHTML = 'Pokaż';
      } else {
        input.type = 'text';
        input.classList.add('pass-visible');
        link.innerHTML = 'Ukryj';
      }
    }
  }
}
