import Validator from '../Validator.js';

export default class RegisterStep3 {
  constructor() {
    if (!this.vars()) return false;
    this.setupEvents();
  }

  vars() {
    this.selectors = {
      form: 'registerStep3',
      single: 'data-validate-single',
      multiple: 'data-validate-multiple',
      wrapper: 'data-validate-field',
      multipleWrapper: 'data-validate-fields',
    };

    this.form = document.getElementById(this.selectors.form);

    if (!this.form) return false;

    this.wrappers = Array.from(this.form.querySelectorAll(`[${this.selectors.wrapper}]`));
    this.multipleWrappers = Array.from(
      this.form.querySelectorAll(`[${this.selectors.multipleWrapper}]`)
    );

    this.basicFields = [
      {
        name: 'languages',
        rules: [
          {
            type: 'two-selects',
            subfield: 'levels',
          },
        ],
      },
      {
        name: 'preferedLang',
        rules: [
          {
            type: 'oneCheckbox',
          },
        ],
      },
      // {
      //   name: 'skills',
      //   rules: [
      //     {
      //       type: 'min-one-checkbox',
      //     },
      //   ],
      // },
      {
        name: 'passportNumber',
        rules: [
          {
            type: 'multipleRegexpOrEmpty',
            regexp: '^([a-zA-Z0-9]{3,30})$',
          },
        ],
      },
      {
        name: 'visaNumber',
        rules: [
          {
            type: 'multipleRegexpOrEmpty',
            regexp: '^([a-zA-Z0-9]{3,11})$',
          },
        ],
      }
    ];

    this.setupValidator();

    return true;
  }

  setupEvents() {
    this.form.addEventListener('submit', (event) => {
      if (!this.validator.validate() || !this.checkCv() || !this.checkDocs()) event.preventDefault();
    });
  }

  checkDocs() {
    const visas = document.querySelectorAll('[name="visaNumber[]"]');
    const passports = document.querySelectorAll('[name="passportNumber[]"]');
    let result = true;

    Array.from(visas).forEach((visa) => {
      const closest = visa.closest('div');
      if (visa.value.length > 30) {
        visa.value = visa.value.substring(0, 30);
        result = false;
        if (closest) closest.classList.add('has-error');
      }
    });

    Array.from(passports).forEach((pass) => {
      const closest = pass.closest('div');
      if (pass.value.length > 30) {
        pass.value = pass.value.substring(0, 30);
        result = false;
        if (closest) closest.classList.add('has-error');
      }
    });

    return result;
  }

  checkCv() {
    const jobs = document.querySelectorAll('[name="cvJob[]"]');
    const names = document.querySelectorAll('[name="cvName[]"]');
    let result = true;

    Array.from(jobs).forEach((job) => {
      const closest = job.closest('div');
      if (job.value.length > 60) {
        job.value = job.value.substring(0, 60);
        result = false;
        if (closest) closest.classList.add('has-error');
      }
    });

    Array.from(names).forEach((name) => {
      const closest = name.closest('div');
      if (name.value.length > 60) {
        name.value = name.value.substring(0, 60);
        result = false;
        if (closest) closest.classList.add('has-error');
      }
    });

    return result;
  }

  setupValidator() {
    this.fields = this.basicFields;

    this.wrappers.forEach((wrapper) => {
      const select = wrapper.querySelector(`[${this.selectors.single}="select"]`);
      const date = wrapper.querySelector(`[${this.selectors.single}="date"]`);
      const cb = wrapper.querySelector(`[${this.selectors.single}="checkbox"]`);
      if (select && date && cb) {
        this.fields.push({
          name: cb.getAttribute('name'),
          rules: [
            {
              type: 'single-date-cb-select',
              subfield: date.getAttribute('name'),
              secondarySubfield: select.getAttribute('name'),
            },
          ],
        });
      } else if (select && date) {
        this.fields.push({
          name: select.getAttribute('name'),
          rules: [
            {
              type: 'single-select-with-date',
              subfield: date.getAttribute('name'),
            },
          ],
        });
      } else if (date && cb) {
        this.fields.push({
          name: cb.getAttribute('name'),
          rules: [
            {
              type: 'single-checkbox-with-date',
              subfield: date.getAttribute('name'),
            },
          ],
        });
      }
    });

    this.multipleWrappers.forEach((wrapper) => {
      const select = wrapper.querySelector(`[${this.selectors.single}="select"]`);
      const date = wrapper.querySelector(`[${this.selectors.single}="date"]`);
      const cb = wrapper.querySelector(`[${this.selectors.single}="checkbox"]`);

      if (select && date && cb) {
        this.fields.push({
          name: cb.getAttribute('name'),
          rules: [
            {
              type: 'multiple-date-cb-select',
              subfield: date.getAttribute('name'),
              secondarySubfield: select.getAttribute('name'),
            },
          ],
        });
      } else if (select && date) {
        this.fields.push({
          name: select.getAttribute('name'),
          rules: [
            {
              type: 'multiple-select-with-date',
              subfield: date.getAttribute('name'),
            },
          ],
        });
      } else if (date && cb) {
        this.fields.push({
          name: cb.getAttribute('name'),
          rules: [
            {
              type: 'multiple-checkbox-with-date',
              subfield: date.getAttribute('name'),
            },
          ],
        });
      }
    });

    this.validator = new Validator(this.fields, this.form);
  }
}
