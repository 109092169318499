export default class ScrollTo {
  constructor() {
    if (this.vars()) {
      this.setupEvents();
    }
  }

  vars() {
    this.scrolls = document.querySelectorAll('[data-scroll-to]');

    if (!this.scrolls.length) return false;

    return true;
  }

  setupEvents() {
    Array.from(this.scrolls).forEach((item) => {
      item.addEventListener('click', () => ScrollTo.handleScroll(item));
    });
  }

  static handleScroll(item) {
    const target = item.getAttribute('data-scroll-to');
    const targetEl = document.getElementById(target);
    const top = ScrollTo.calcTop(targetEl);
    ScrollTo.scroll(top);
  }

  static calcTop(el) {
    const { pageYOffset } = window;
    const { top } = el.getBoundingClientRect();
    const header = document.querySelector('.header');
    const headerHeight = header.getBoundingClientRect().height;

    return pageYOffset + top - headerHeight * 2;
  }

  static scroll(y) {
    window.scrollTo({
      top: y,
      behavior: 'smooth',
    });
  }
}
