import Validator from '../Validator.js';

export default class RegisterStep2 {
  constructor() {
    if (!this.vars()) return false;
    this.setupEvents();
  }

  vars() {
    this.selectors = {
      form: 'registerStep2',
      single: 'data-validate-single',
      multiple: 'data-validate-multiple',
      wrapper: 'data-validate-field',
      multipleWrapper: 'data-validate-fields',
    };

    this.form = document.getElementById(this.selectors.form);

    if (!this.form) return false;

    this.wrappers = Array.from(this.form.querySelectorAll(`[${this.selectors.wrapper}]`));
    this.multipleWrappers = Array.from(
      this.form.querySelectorAll(`[${this.selectors.multipleWrapper}]`)
    );

    this.setupValidator();

    return true;
  }

  setupEvents() {
    this.form.addEventListener('submit', (event) => {
      if (!this.validator.validate()) event.preventDefault();
    });
  }

  setupValidator() {
    this.fields = [];
    this.wrappers.forEach((wrapper) => {
      const select = wrapper.querySelector(`[${this.selectors.single}="select"]`);
      const date = wrapper.querySelector(`[${this.selectors.single}="date"]`);
      const cb = wrapper.querySelector(`[${this.selectors.single}="checkbox"]`);
      if (select && date) {
        this.fields.push({
          name: select.getAttribute('name'),
          rules: [
            {
              type: 'single-select-with-date',
              subfield: date.getAttribute('name'),
            },
          ],
        });
      } else if (date && cb) {
        this.fields.push({
          name: cb.getAttribute('name'),
          rules: [
            {
              type: 'single-checkbox-with-date',
              subfield: date.getAttribute('name'),
            },
          ],
        });
      }
    });

    this.multipleWrappers.forEach((wrapper) => {
      const select = wrapper.querySelector(`[${this.selectors.single}="select"]`);
      const date = wrapper.querySelector(`[${this.selectors.single}="date"]`);
      const cb = wrapper.querySelector(`[${this.selectors.single}="checkbox"]`);
      if (select && date) {
        this.fields.push({
          name: select.getAttribute('name'),
          rules: [
            {
              type: 'multiple-select-with-date',
              subfield: date.getAttribute('name'),
            },
          ],
        });
      } else if (date && cb) {
        this.fields.push({
          name: cb.getAttribute('name'),
          rules: [
            {
              type: 'single-checkbox-with-date',
              subfield: date.getAttribute('name'),
            },
          ],
        });
      }
    });
    this.validator = new Validator(this.fields, this.form);
  }
}
