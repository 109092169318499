import Validator from '../Validator.js';

export default class EditBasic {
  constructor() {
    if (!this.vars()) return false;
    this.setupEvents();
  }

  vars() {
    this.selectors = {
      form: 'editBasic',
    };

    this.form = document.getElementById(this.selectors.form);

    if (!this.form) return false;

    this.fields = [
      {
        name: 'password',
        rules: [
          {
            type: 'regexpOrEmpty',
            regexp: '^(?=\\S*[A-Z])(?=\\S*[A-Z])(?=\\S*\\d)(?=\\S*[^\\w\\s])\\S{8,}$',
          },
        ],
      },
      {
        name: 'password_confirmation',
        rules: [
          {
            type: 'match',
            field: 'password',
          },
        ],
      },
      {
        name: 'name',
        rules: [
          {
            type: 'regexp',
            regexp: '^(.{3,30})$',
          },
        ],
      },
      {
        name: 'surname',
        rules: [
          {
            type: 'regexp',
            regexp: '^(.{3,30})$',
          },
        ],
      },
      {
        name: 'email',
        rules: [
          {
            type: 'regexp',
            regexp:
              '[A-Z0-9a-z._%+-]+@[a-zęóąśłżźćńA-ZĘÓĄŚŁŻŹĆŃ0-9.-]+\\.[a-zęóąśłżźćńA-ZĘÓĄŚŁŻŹĆŃ]{2,50}',
          },
        ],
      },
      {
        name: 'phone',
        rules: [
          {
            type: 'regexp',
            regexp:
              '\\+(9[976]\\d|8[987530]\\d|6[987]\\d|5[90]\\d|42\\d|3[875]\\d|2[98654321]\\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\\d{9,11}$',
          },
        ],
      },
      {
        name: 'nationalities',
        rules: [
          {
            type: 'select-multiple',
          },
        ],
      },
    ];

    this.validator = new Validator(this.fields, this.form);

    return true;
  }

  setupEvents() {
    this.form.addEventListener('submit', (event) => {
      if (!this.validator.validate()) event.preventDefault();
    });
  }
}
