export default class ShowMore {
  constructor() {
    if (!this.vars()) return false;
    this.setupEvents();
  }

  vars() {
    this.selectors = {
      button: 'data-show-more',
    };

    this.buttons = Array.from(document.querySelectorAll(`[${this.selectors.button}]`));
    if (!this.buttons) return false;

    return true;
  }

  setupEvents() {
    this.buttons.forEach((button) => {
      button.addEventListener('click', (e) => {
        e.preventDefault();
        this.toggle(button);
      });
    });
  }

  toggle(button) {
    const target = button.nextSibling.nextSibling;
    const prev = button.previousSibling.previousSibling;

    if (button.classList.contains('active')) {
      target.style.display = 'none';
    } else {
      target.style.display = 'block';
    }

    if (button.getAttribute(this.selectors.button) !== 'noPrev') {
      prev.style.display = 'none';
      button.style.display = 'none';
    } else {
      if (button.classList.contains('active')) {
        button.classList.remove('active');
        button.innerHTML = '(Więcej)';
      } else {
        button.innerHTML = '(Mniej)';
        button.classList.add('active');
      }
    }
    // if (target) {
    //   if (target.classList.contains('visible')) {
    //     target.style.display = 'none';
    //     target.classList.remove('visible');
    //   } else {
    //     target.style.display = 'block';
    //     target.classList.add('visible');
    //   }
    // }
    ShowMore.dispatchEvent('expander-refresh');
  }

  static dispatchEvent(event, detail = {}) {
    window.dispatchEvent(
      new CustomEvent(event, {
        detail,
      })
    );
  }
}
