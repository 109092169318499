export default class Tabs {
  constructor() {
    if (!this.vars()) return false;
    this.setupEvents();
  }

  vars() {
    this.selectors = {
      tabs: 'data-tabs',
      target: 'data-tabs-target',
      active: 'active',
      tab: 'data-tab',
      nav: 'data-tabs-nav',
    };

    this.tabs = Array.from(document.querySelectorAll(`[${this.selectors.tabs}]`));

    if (!this.tabs.length) return false;
    return true;
  }

  setupEvents() {
    this.tabs.forEach((tabs) => {
      const nav = tabs.querySelector(`[${this.selectors.nav}]`);
      const buttons = Array.from(nav.querySelectorAll(`[${this.selectors.target}]`));
      buttons.forEach((button) => {
        button.addEventListener('click', () => this.toggle(button, tabs));
      });
    });
  }

  toggle(button, tabs) {
    const targetId = button.getAttribute(this.selectors.target);
    const targetEl = document.getElementById(targetId);

    if (!targetEl) return false;

    const currentButtons = tabs.querySelectorAll(
      `[${this.selectors.target}].${this.selectors.active}`
    );
    const currentTabs = tabs.querySelectorAll(`[${this.selectors.tab}].${this.selectors.active}`);

    if (currentButtons.length) {
      currentButtons.forEach((btn) => {
        const closestTabs = btn.closest(`[${this.selectors.tabs}]`);
        if (closestTabs.isSameNode(tabs)) {
          btn.classList.remove(this.selectors.active);
        }
      });
    }
    if (currentTabs.length) {
      currentTabs.forEach((tab) => {
        const closestTabs = tab.closest(`[${this.selectors.tabs}]`);
        if (closestTabs.isSameNode(tabs)) {
          tab.classList.remove(this.selectors.active);
          tab.style.display = 'none';
        }
      });
    }

    targetEl.classList.add(this.selectors.active);
    targetEl.style.display = 'block';
    button.classList.add(this.selectors.active);

    Tabs.dispatchEvent('expander-refresh');
  }

  static dispatchEvent(event, detail = {}) {
    window.dispatchEvent(
      new CustomEvent(event, {
        detail,
      })
    );
  }
}
