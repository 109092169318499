import { isValidIBAN, isValidBIC } from 'ibantools';
import ScrollTo from '../global/ScrollTo';

export default class Validator {
  constructor(fields, form) {
    if (!this.vars(fields, form)) return false;
    this.setupEvents();
  }

  vars(fields, form) {
    if (!fields || !form) return false;

    this.selectors = {
      wrapper: 'form-group',
      error: 'has-error',
      checkboxWrapper: 'checkbox',
    };

    this.fields = fields;
    this.form = form;
    this.errorBox = document.getElementById('js-error');
    this.successBox = document.getElementById('success-alert');
    this.errors = [];

    return true;
  }

  setupEvents() {}

  validate() {
    this.clearErrors();
    this.fields.forEach((field, i) => {
      let fieldEl = document.querySelector(`[name="${field.name}"]`);

      field.rules.forEach((rule) => {
        switch (rule.type) {
          case 'regexp':
            this.validateRegExp(fieldEl, rule);
            break;
          case 'regexpOrEmpty':
            this.validateRegExpOrEmpty(fieldEl, rule);
            break;
          case 'multipleRegexpOrEmpty':
            this.validateMultipleRegExpOrEmpty(field, rule);
            break;
          case 'match':
            this.validateMatchingValues(fieldEl, rule);
            break;
          case 'select-multiple':
            fieldEl = document.querySelector(`[name="${field.name}[]"]`);
            this.validateSelectMultiple(fieldEl);
            break;
          case 'checkbox':
            this.validateRequiredCheckbox(fieldEl);
            break;
          case 'oneCheckbox':
            this.validateAtLeastOneCheckbox(field);
            break;
          case 'two-selects':
            this.twoSelects(field);
            break;
          case 'select-required':
            this.selectRequired(fieldEl);
            break;
          case 'min-one-checkbox':
            this.minOneCheckbox(field);
            break;
          case 'iban':
            this.validateIban(field);
            break;
          case 'bic':
            this.validateBic(field);
            break;
          case 'single-select-with-date':
            this.singleSelectWithDate(field);
            break;
          case 'multiple-select-with-date':
            this.multipleSelectWithDate(field);
            break;
          case 'single-checkbox-with-date':
            this.singleCheckboxWithDate(field);
            break;
          case 'multiple-checkbox-with-date':
            this.multipleCheckboxWithDate(field);
            break;
          case 'single-date-cb-select':
            this.singleDateCbSelect(field);
            break;
          case 'multiple-date-cb-select':
            this.multipleDateCbSelect(field);
            break;
          case 'bank-account-row':
            this.bankAccountRow(field);
            break;
          case 'radio-required':
            this.radioRequired(field);
            break;
          case 'label-and-file-or-empty':
            this.labelAndFileOrEmpty(field);
            break;
          default:
            break;
        }
      });
    });

    this.showErrors();

    if (this.errors.length > 0 && this.errorBox) {
      this.errorBox.style.display = 'block';
      if (this.successBox) this.successBox.style.display = 'none';
      ScrollTo.scroll(0);
    }
    return this.errors.length < 1;
  }

  validateRegExp(field, rule) {
    if (!field.value || !field.value.match(rule.regexp)) {
      this.errors.push(field);
    }
  }

  validateRegExpOrEmpty(field, rule) {
    if (field.value && !field.value.match(rule.regexp)) {
      this.errors.push(field);
    }
  }

  validateMultipleRegExpOrEmpty(field, rule) {
    const fields = document.querySelectorAll(`[name="${field.name}[]"]`);
    Array.from(fields).forEach((item) => {
      if (item.value && !item.value.match(rule.regexp)) {
        this.errors.push(item);
      }
    });
  }

  validateMatchingValues(field, rule) {
    const matchWith = document.querySelector(`[name="${rule.field}"]`);
    if (matchWith && field.value !== matchWith.value) {
      this.errors.push(field);
    }
  }

  validateSelectMultiple(field) {
    if ($(field).val().length < 1) this.errors.push(field);
  }

  validateRequiredCheckbox(field) {
    if (!field.checked) this.errors.push(field);
  }

  validateAtLeastOneCheckbox(field) {
    const items = document.querySelectorAll(`[name="${field.name}[]"]`);
    let hasError = true;
    Array.from(items).forEach((item) => {
      if (item.checked) {
        hasError = false;
      }
    });
    if (hasError) this.errors.push(items[0]);
  }

  getErrorFields() {
    return this.errors;
  }

  clearErrors() {
    this.errors = [];
    const invalidFields = Array.from(document.querySelectorAll(`.${this.selectors.error}`));
    if (!invalidFields.length) return false;
    invalidFields.forEach((field) => {
      field.classList.remove(this.selectors.error);
    });
  }

  showErrors() {
    if (!this.errors.length) return false;
    this.errors.forEach((errorItem) => {
      let wrapper = null;
      if (errorItem.matches('input[type="checkbox"]')) {
        wrapper = errorItem.closest(`.${this.selectors.checkboxWrapper}`);
      } else {
        wrapper = errorItem.closest(`.${this.selectors.wrapper}`);
      }
      if (wrapper) wrapper.classList.add(this.selectors.error);
      const cbGroup = errorItem.closest('.hiddenRules');
      if (cbGroup) {
        Validator.dispatchEvent('show-rules-group');
      }
      const cbGroupWrapper = errorItem.closest('.form-group');
      const hiddenError = cbGroupWrapper.querySelector('.hiddenError');
      if (hiddenError) {
        hiddenError.classList.add('has-error');
      }
    });
  }

  twoSelects(field) {
    const fieldsA = document.querySelectorAll(`[name="${field.name}[]"]`);
    const fieldsB = document.querySelectorAll(`[name="${field.rules[0].subfield}[]"]`);
    let correct = false;
    let uncorrect = null;
    Array.from(fieldsA).forEach((a, index) => {
      if (!a.getAttribute('data-duplicate-init')){
        const b = fieldsB[index];
        if (a.value == -1 && b.value != -1) {
          this.errors.push(a);
          uncorrect = a;
        } else if (a.value != -1 && b.value == -1) {
          this.errors.push(b);
          uncorrect = b;
        } else if (a.value != -1 && b.value != -1) {
          correct = true;
        } else if (a.value == -1 && b.value == -1) {
          this.errors.push(a);
          this.errors.push(b);
        }
      }
    });

    if (!correct && uncorrect) {
      this.errors.push(uncorrect);
    }
  }

  selectRequired(field) {
    if (field.value == -1 || !field.value.length) this.errors.push(field);
  }

  minOneCheckbox(field) {
    const fields = document.querySelectorAll(`[name="${field.name}[]"]`);
    let correct = false;
    Array.from(fields).forEach((field) => {
      if (field.checked) correct = true;
    });
    if (!correct) {
      Array.from(fields).forEach((field) => {
        this.errors.push(field);
      });
    }
  }

  validateIban(field) {
    const fields = document.querySelectorAll(`[name="${field.name}[]"]`);
    Array.from(fields).forEach((field) => {
      if (field.value.length && !isValidIBAN(field.value)) this.errors.push(field);
    });
  }

  validateBic(field) {
    const fields = document.querySelectorAll(`[name="${field.name}[]"]`);
    Array.from(fields).forEach((field) => {
      if (field.value.length && !isValidBIC(field.value)) this.errors.push(field);
    });
  }

  singleSelectWithDate(field) {
    const fieldA = document.querySelector(`[name="${field.name}"]`);
    const fieldB = document.querySelector(`[name="${field.rules[0].subfield}"]`);
    if (
      (fieldA.value == -1 || !fieldA.value.length) &&
      (fieldB.value.length && fieldB.value != -1)
    ) {
      this.errors.push(fieldA);
    } else if (
      fieldA.value != -1 &&
      fieldA.value.length &&
      (!fieldB.value.length || fieldB.value == -1)
    ) {
      this.errors.push(fieldB);
    }
  }

  multipleSelectWithDate(field) {
    const fieldsA = document.querySelectorAll(`[name="${field.name}"]`);
    const fieldsB = document.querySelectorAll(`[name="${field.rules[0].subfield}"]`);
    Array.from(fieldsA).forEach((a, index) => {
      const b = fieldsB[index];
      if ((!a.value.length || a.value == -1) && (b.value.length && b.value != -1)) {
        this.errors.push(a);
      } else if (a.value.length && a.value != -1 && (!b.value.length || b.value.length == -1)) {
        this.errors.push(b);
      }
    });
  }

  singleCheckboxWithDate(field) {
    const fieldA = document.querySelector(`[name="${field.name}"]`);
    const fieldB = document.querySelector(`[name="${field.rules[0].subfield}"]`);
    if (!fieldA.checked && fieldB.value.length) {
      this.errors.push(fieldA);
    } else if (fieldA.checked && !fieldB.value.length) {
      this.errors.push(fieldB);
    }
  }

  multipleCheckboxWithDate(field) {
    const fieldsA = document.querySelectorAll(`[name="${field.name}"]`);
    const fieldsB = document.querySelectorAll(`[name="${field.rules[0].subfield}"]`);
    Array.from(fieldsA).forEach((a, index) => {
      const b = fieldsB[index];
      if (!a.checked && b.value.length) {
        this.errors.push(a);
      } else if (a.checked && !b.value.length) {
        this.errors.push(b);
      }
    });
  }

  singleDateCbSelect(field) {
    const fieldA = document.querySelector(`[name="${field.name}"]`);
    const fieldB = document.querySelector(`[name="${field.rules[0].subfield}"]`);
    const fieldC = document.querySelector(`[name="${field.rules[0].secondarySubfield}"]`);

    if (fieldA.checked && !fieldB.value.length) {
      this.errors.push(fieldB);
    }

    if (fieldA.checked && (!fieldC.value.length || fieldC.value == -1)) {
      this.errors.push(fieldC);
    }

    if (!fieldA.checked && fieldB.value.length) {
      this.errors.push(fieldA);
    }

    if (!fieldA.checked && (fieldC.value.length && fieldC.value != -1)) {
      this.errors.push(fieldA);
    }

    if (!fieldB.value.length && (fieldC.value.length && fieldC.value != -1)) {
      this.errors.push(fieldB);
    }

    if (fieldB.value.length && (!fieldC.value.length || fieldC.value == -1)) {
      this.errors.push(fieldC);
    }
  }

  multipleDateCbSelect(field) {
    const fieldsA = document.querySelectorAll(`[name="${field.name}"]`);
    const fieldsB = document.querySelectorAll(`[name="${field.rules[0].subfield}"]`);
    const fieldsC = document.querySelectorAll(`[name="${field.rules[0].secondarySubfield}"]`);
    Array.from(fieldsA).forEach((a, index) => {
      const b = fieldsB[index];
      const c = fieldsC[index];
      if (a.checked && !b.value.length) {
        this.errors.push(b);
      }

      if (a.checked && (!c.value.length || c.value == -1)) {
        this.errors.push(c);
      }

      if (!a.checked && b.value.length) {
        this.errors.push(a);
      }

      if (!a.checked && (c.value.length && c.value != -1)) {
        this.errors.push(a);
      }

      if (!b.value.length && (c.value.length && c.value != -1)) {
        this.errors.push(b);
      }

      if (b.value.length && (!c.value.length || c.value == -1)) {
        this.errors.push(c);
      }
    });
  }

  bankAccountRow(field) {
    const selects = document.querySelectorAll(`[name="${field.name}"]`);
    const numbers = document.querySelectorAll(`[name="${field.rules[0].subfield.name}"]`);
    const names = document.querySelectorAll(`[name="${field.rules[0].secondarySubfield.name}"]`);
    let atLeastOne = false;
    let hasErrors = false;

    Array.from(selects).forEach((select, index) => {
      const number = numbers[index];
      const name = names[index];

      if ((select.value == '-1' || !select.value.length) && (number.value.length || name.value.length)) {
        this.errors.push(select);
        hasErrors = true;
      }

      if (!number.value.length && (name.value.length || (select.value != '-1' && select.value.length))) {
        this.errors.push(number);
        hasErrors = true;
      } else if (number.value.length && !number.value.match(field.rules[0].subfield.regexp)) {
        this.errors.push(number);
        hasErrors = true;
      }

      if (!name.value.length && (number.value.length || (select.value != '-1' && select.value.length))) {
        this.errors.push(name);
        hasErrors = true;
      } else if (name.value.length && !name.value.match(field.rules[0].secondarySubfield.regexp)) {
        this.errors.push(name);
        hasErrors = true;
      }

      if ((select.value != '-1' && select.value.length) && name.value.length && number.value.length) {
        atLeastOne = true;
      }
    });

    if (!atLeastOne && !hasErrors) {
      this.errors.push(Array.from(selects)[0]);
      this.errors.push(Array.from(numbers)[0]);
      this.errors.push(Array.from(names)[0]);
    }
  }

  radioRequired(field) {
    const radios = Array.from(document.querySelectorAll(`[name="${field.name}"]`));
    let isValid = false;
    radios.forEach((radio) => {
      if (radio.checked) isValid = true;
    })
    if (!isValid) {
      radios.forEach((radio) => this.errors.push(radio));
    }
  }

  labelAndFileOrEmpty(field) {
    const labels = Array.from(document.querySelectorAll(`[name="${field.name}[]"]`));
    const files = Array.from(document.querySelectorAll(`[name="${field.rules[0].subfield}[]"]`));
    const oldFiles = Array.from(document.querySelectorAll(`[name="${field.rules[0].editfield}[]"]`));

    if (oldFiles.length) {
      labels.forEach((label, index) => {
        const file = files[index];
        const oldFile = oldFiles[index];

        if (oldFile) {
          if (!label.value.length && (file.files.length || oldFile.value.length)) {
            this.errors.push(label);
          } else if (label.value.length && (!file.files.length && !oldFile.value.length)) {
            this.errors.push(file);
          }
        } else {
          if (!label.value.length && file.files.length) {
            this.errors.push(label);
          } else if (label.value.length && !file.files.length) {
            this.errors.push(file);
          }
        }
      });
    } else {
      labels.forEach((label, index) => {
        const file = files[index];
        if (!label.value.length && file.files.length) {
          this.errors.push(label);
        } else if (label.value.length && !file.files.length) {
          this.errors.push(file);
        }
      });
    }
  }

  static dispatchEvent(event, detail = {}) {
    window.dispatchEvent(
      new CustomEvent(event, {
        detail,
      })
    );
  }
}
