import Reveal from './Reveal';
import Duplicate from './Duplicate';
import Expander from './Expander';
import Tabs from './Tabs';
import Tooltips from './Tooltips';
import CbShow from './CbShow';
import AvatarInput from './AvatarInput';
import CheckboxToggleAll from './CheckboxToggleAll';
import BusinessLabel from './BusinessLabel';
import PreferedLangHelper from './PreferedLangHelper';
import RemoveFilter from './RemoveFilter';
import CharacterLimit from './CharacterLimit';
import ShowMore from './ShowMore';
import DisableSameOptions from './DisableSameOptions';
import FiltersReset from './FiltersReset';
import BatchRemove from './BatchRemove';
import BatchCv from './BatchCv';
import BatchXls from './BatchXls';
import ShowPassword from './ShowPassword';
import ShowRules from './ShowRules';
import BatchMail from './BatchMail';
import SendPasswordTo from './SendPasswordTo';
import ProjectPmInput from './ProjectPmInput';

const initGlobals = () => {
  new Reveal();
  new Duplicate();
  new Expander();
  new Tabs();
  new Tooltips();
  new CbShow();
  new AvatarInput();
  new CheckboxToggleAll();
  new BusinessLabel();
  new PreferedLangHelper();
  new RemoveFilter();
  new CharacterLimit();
  new ShowMore();
  new DisableSameOptions();
  new FiltersReset();
  new BatchRemove();
  new BatchCv();
  new BatchXls();
  new ShowPassword();
  new ShowRules();
  new BatchMail();
  new SendPasswordTo();
  new ProjectPmInput();
};

export default initGlobals;
