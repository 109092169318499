import axios from 'axios';

export default class SendPasswordTo {
  constructor() {
    if (!this.vars()) return false;
    this.setupEvents();
    return true;
  }

  vars() {
    this.buttons = Array.from(document.querySelectorAll('[data-mail-password-to]'));

    if (!this.buttons.length) return false;
    return true;
  }

  setupEvents() {
    this.buttons.forEach((btn) => {
      const target = btn.getAttribute('data-mail-password-to');
      const url = btn.getAttribute('data-mail-password-to-url');

      if (target && url) {
        btn.addEventListener('click', (e) => {
          e.preventDefault();
          this.send(target, url, btn);
        });
      }
    });
  }

  send(target, url, btn) {
    this.setWaiting(btn);

    axios.post(url, { id: target })
    .then((response) => {
      if (response && response.data.code === 200) {
        this.setSuccess(btn);
      } else {
        throw new Error(response);
      }
    })
    .catch((error) => {
      this.setError(btn);
    });
  }

  setWaiting(btn) {
    this.clearClasses(btn);
    btn.disabled = true;
    btn.classList.add('btn-secondary');
    btn.innerText = 'Wysyłam...';
  }

  setError(btn) {
    this.clearClasses(btn);
    btn.classList.add('btn-danger');
    btn.innerText = 'Błąd, ponów';
  }

  setSuccess(btn) {
    this.clearClasses(btn);
    btn.disabled = true;
    btn.classList.add('btn-success');
    btn.innerText = 'Wysłano';
  }

  setDefault(btn) {
    this.clearClasses(btn);
    btn.classList.add('btn-primary');
    btn.innerText = 'Wyślij hasło';
  }

  clearClasses(btn) {
    btn.disabled = false;
    btn.classList.remove('btn-primary');
    btn.classList.remove('btn-success');
    btn.classList.remove('btn-warning');
  }
}
