export default class Tooltips {
  constructor() {
    if (!this.vars()) return false;
  }

  vars() {
    this.selectors = {
      tooltip: 'data-tooltip',
      tooltipText: 'data-tooltip-text',
    };

    this.tooltips = document.querySelectorAll(`[${this.selectors.tooltip}]`);

    if (!this.tooltips.length) return false;

    Array.from(this.tooltips).forEach((tooltip) => {
      const title = tooltip.getAttribute(this.selectors.tooltipText);
      const placement = tooltip.getAttribute(this.selectors.tooltip);

      new Tooltip(tooltip, {
        placement,
        title,
        trigger: 'hover',
      });
    });
  }
}
