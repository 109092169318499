export default class RemoveFilter {
  constructor() {
    if (!this.vars()) return false;
    this.setupEvents();
  }

  vars() {
    this.selectors = {
      remove: 'data-remove-filter',
      type: 'data-remove-filter-type',
      value: 'data-remove-value'
    };

    this.buttons = Array.from(document.querySelectorAll(`[${this.selectors.remove}]`));

    if (!this.buttons.length) return false;

    return true;
  }

  setupEvents() {
    this.buttons.forEach((button) => {
      button.addEventListener('click', () => this.remove(button));
    });
  }

  remove(button) {
    const target = button.getAttribute(this.selectors.remove);
    const type = button.getAttribute(this.selectors.type);
    const value = button.getAttribute(this.selectors.value);

    if (type === 'text') {
      this.handleTextField(target);
    } else if (type === 'select') {
      this.handleSelectField(target);
    } else if (type === 'multiselect') {
      this.handleMultiSelectField(target, value);
    }

    this.sendForm(target);
  }

  handleTextField(target) {
    const targetEl = document.querySelector(`[name="${target}"]`);
    targetEl.value = '';
  }

  handleSelectField(target) {
    const targetEl = document.querySelector(`[name="${target}"]`);
    $(targetEl)
      .val(null)
      .trigger('change');
  }

  handleMultiSelectField(target, value) {
    const targetEl = document.querySelector(`[name="${target}"]`);
    const targetOption = targetEl.querySelector(`option[value="${value}"]`);
    targetOption.selected = false;
    $(targetEl).trigger('change');
  }

  sendForm(target) {
    const targetEl = document.querySelector(`[name="${target}"]`);
    const form = targetEl.closest('form');
    form.submit();
  }
}
