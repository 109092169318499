import Validator from '../Validator.js';

export default class createProject {
  constructor() {
    if (!this.vars()) return false;
    this.setupEvents();
  }

  vars() {
    this.selectors = {
      form: 'createProject',
    };

    this.form = document.getElementById(this.selectors.form);

    if (!this.form) return false;

    this.fields = [
      {
        name: 'name',
        rules: [
          {
            type: 'regexp',
            regexp: '^(.{3,60})$',
          },
        ],
      },
      {
        name: 'status',
        rules: [
          {
            type: 'select-required',
          },
        ],
      },
      {
        name: 'country',
        rules: [
          {
            type: 'select-required',
          },
        ],
      },
      {
        name: 'city',
        rules: [
          {
            type: 'regexp',
            regexp: '^(.{3,60})$',
          },
        ],
      },
      {
        name: 'start_date',
        rules: [
          {
            type: 'regexp',
            regexp: '^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\\d{4}$',
          },
        ],
      },
      {
        name: 'end_date',
        rules: [
          {
            type: 'regexp',
            regexp: '^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\\d{4}$',
          },
        ],
      },
      {
        name: 'operational_manager',
        rules: [
          {
            type: 'select-required',
          },
        ],
      },
      {
        name: 'operational_manager_status',
        rules: [
          {
            type: 'select-required',
          },
        ],
      },
      {
        name: 'workers',
        rules: [
          {
            type: 'two-selects',
            subfield: 'workers_statuses',
          },
        ],
      },
      {
        name: 'project_pm_role',
        rules: [
          {
            type: 'radio-required',
          },
        ],
      },
      {
        name: 'desc',
        rules: [
          {
            type: 'regexp',
            regexp: '^(.[\\s\\S]{3,5000})$',
          },
        ],
      },
      {
        name: 'docs_names',
        rules: [
          {
            type: 'label-and-file-or-empty',
            subfield: 'docs_files',
            editfield: 'old_docs_files'
          },
        ],
      },
      {
        name: 'attachments_names',
        rules: [
          {
            type: 'label-and-file-or-empty',
            subfield: 'attachments_files',
            editfield: 'old_attachments_files'
          },
        ],
      },
      {
        name: 'timesheet_format',
        rules: [
          {
            type: 'select-required',
          },
        ],
      },
    ];

    this.validator = new Validator(this.fields, this.form);

    return true;
  }

  setupEvents() {
    this.form.addEventListener('submit', (event) => {
      // event.preventDefault();
      // this.validator.validate();
      if (!this.validator.validate()) event.preventDefault();
    });
  }
}
