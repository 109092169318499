export default class FiltersReset {
  constructor() {
    if (!this.vars()) return false;
    this.setupEvents();
  }

  vars() {
    this.selectors = {
      mainFiltersButton: 'mainFilters',
      sidebarFiltersButton: 'sidebarFilters',
      wrapper: 'data-filters-wrapper'
    };

    this.mainFiltersButton = document.getElementById(this.selectors.mainFiltersButton);
    this.sidebarFiltersButton = document.getElementById(this.selectors.sidebarFiltersButton);

    // if (!this.mainFiltersButton || !this.sidebarFiltersButton) return false;

    return true;
  }

  setupEvents() {
    if (this.mainFiltersButton) {
      this.mainFiltersButton.addEventListener('click', (e) => this.clear(e, this.mainFiltersButton));
    }

    if (this.sidebarFiltersButton) {
      this.sidebarFiltersButton.addEventListener('click', (e) => this.clear(e, this.sidebarFiltersButton));
    }
  }

  clear(e, btn) {
    e.preventDefault();
    const wrapper = btn.closest(`[${this.selectors.wrapper}]`);
    const inputs = wrapper.querySelectorAll('input[type="text"]');
    const selects = wrapper.querySelectorAll('select');
    Array.from(inputs).forEach((input) => input.value = '');
    Array.from(selects).forEach((select) => $(select).val('-1').trigger('change'));
    this.submit(btn);
  }

  submit(btn) {
    const parent = btn.closest('div');
    const submit = parent.querySelector('button[type="submit"]');
    submit.click();
  }
}
