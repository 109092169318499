export default class Reveal {
  constructor() {
    if (!this.vars()) return false;
    this.setupEvents();
  }

  vars() {
    this.selectors = {
      wrapper: 'data-reveal',
      buttonWrapper: 'data-reveal-button',
      contentWrapper: 'data-reveal-content',
    };

    this.wrappers = document.querySelectorAll(`[${this.selectors.wrapper}]`);

    if (!this.wrappers.length) return false;

    return true;
  }

  setupEvents() {
    Array.from(this.wrappers).forEach((wrapper) => {
      const button = wrapper.querySelector(`[${this.selectors.buttonWrapper}] button`);

      button.addEventListener('click', () => {
        this.reveal(wrapper);
      });
    });
  }

  reveal(wrapper) {
    const buttonWrapper = wrapper.querySelector(`[${this.selectors.buttonWrapper}]`);
    const contentWrapper = wrapper.querySelector(`[${this.selectors.contentWrapper}]`);

    buttonWrapper.style.display = 'none';
    contentWrapper.style.display = 'block';
  }
}
