export default class DisableSameOptions {
  constructor() {
    if (!this.vars()) return false;
    this.setupEvents();
    setTimeout(() => this.disableOptions(), 1000);
  }

  vars() {
    this.selectors = {
      wrapper: 'data-disable-same-option',
    };

    return true;
  }

  setupEvents() {
    window.addEventListener('select-changed', () => {
      setTimeout(() => {
        this.disableOptions();
      }); // fixing some weird select2 behaviour
    });
  }

  disableOptions() {
    const wrappers = document.querySelectorAll(`[${this.selectors.wrapper}]`);
    if (wrappers.length) {
      Array.from(wrappers).forEach((wrapper) => {
        const allSelects = Array.from(wrapper.querySelectorAll('select'));
        const ignoreSelects = ['workers_statuses[]'];
        const selects = [];

        allSelects.forEach((select) => {
          const name = select.getAttribute('name');
          if (ignoreSelects.indexOf(name) === -1) {
            selects.push(select);
          }
        });

        const options = wrapper.querySelectorAll('option[disabled]');
        const values = [];
        Array.from(options).forEach((option) => option.disabled = false);
        Array.from(selects).forEach((select) => {
          if (select.value != '-1' && select.value.length) {
            if (values.indexOf(select.value) < 0) values.push(select.value);
          }
        });
        Array.from(selects).forEach((select) => {
          values.forEach((val) => {
            if (select.value != val) {
              const option = select.querySelector(`option[value="${val}"]`);
              if (option) {
                option.disabled = true;
              }
            }
          });
        });
        Array.from(selects).forEach((select) => {
          if (select.classList.contains('select2')) {
            $(select).select2('destroy');
            $(select).select2().change(() => {
              window.dispatchEvent(new CustomEvent('select-changed'));
            });
          } else if (select.classList.contains('userSelect')) {
            $(select).select2('destroy');
            $(select).select2({
              templateResult: window.formatWithAvatar
            }).change(() => {
              window.dispatchEvent(new CustomEvent('select-changed'));
            });
          }
        });
      });
    }
  }
}
