export default class BatchRemove {
  constructor() {
    if (!this.vars()) return false;
    this.setupEvents();
  }

  vars() {
    this.selectors = {
      trigger: 'data-batch-remove-trigger',
      form: 'batchRemove',
      input: 'batchRemoveInput',
      action: 'data-batch-remove-action',
    };

    this.form = document.getElementById(this.selectors.form);
    this.trigger = document.querySelector(`[${this.selectors.trigger}]`);
    this.input = document.getElementById(this.selectors.input);

    if (!this.form || !this.trigger || !this.input) return false;

    this.action = this.trigger.getAttribute(this.selectors.action);

    this.checkboxName = this.trigger.getAttribute(this.selectors.trigger);

    this.checkboxes = Array.from(document.querySelectorAll(`.${this.checkboxName}`));

    if (!this.checkboxes.length) return false;

    this.input.value = JSON.stringify([]);
    return true;
  }

  setupEvents() {
    this.checkboxes.forEach((cb) => {
      cb.addEventListener('change', () => this.update());
    });

    this.trigger.addEventListener('click', (e) => {
      e.preventDefault();

      if (this.action) {
          const select = document.querySelector(`[name="${this.action}"]`);
          if (select.value == 1) {
            this.form.submit();
          }
      } else {
        this.form.submit();
      }
    });

    window.addEventListener('cbs-toggled', () => this.update());
  }

  update() {
    let ids = [];
    this.checkboxes.forEach((cb) => {
      if (cb.checked) ids.push(parseInt(cb.value));
    });
    this.input.value = JSON.stringify(ids);
  }
}
